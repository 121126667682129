import React, { useRef, useState, useEffect } from 'react'
import Layout from '../components/layout'
import style from './photoshoot.module.css'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

const PhotoshootPage = ({ location, data }) => {
  const [selectedPackage, setSelectedPackage] = React.useState('')

  return (
    <Layout
      location={location}
      packageTypes={data.allContentfulPackageType}
      photoshoots={data.contentfulEntriesGroup}
      className={style.wrapper}
    >
      <Helmet>
        <title>
          {data.contentfulPhotoshoot.name} | Juliana de Souza Photography
        </title>

        <meta
          name="description"
          content={data.contentfulPhotoshoot.description.description}
        />
      </Helmet>

      <div className={style.photoshootIntroGrid}>
        <div className={style.photoshootIntroGridItem}>
          <h1 className={style.titleMd}>{data.contentfulPhotoshoot.name}</h1>

          <p>{data.contentfulPhotoshoot.description.description}</p>
        </div>
      </div>

      {data.contentfulPhotoshoot.firstGallery.length === 4 ? (
        <div className={style.verticalGalleryGrid}>
          {data.contentfulPhotoshoot.firstGallery.map((image) => (
            <div className={style.verticalGalleryGridItem} key={image.file.url}>
              <img
                sizes="(min-width: 1400px) calc((100vw - (((100vw - 1360px) / 2)* 2)) / 4), (min-width: 768px) 25vw, 100vw"
                src={`${image.file.url}?w=660`}
                width={332}
                height={498}
                srcSet={`${image.file.url}?w=660 660w, ${image.file.url}?w=990 990w, ${image.file.url}?w=1320 1320w`}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className={style.horizontalGalleryGrid}>
          {data.contentfulPhotoshoot.firstGallery.map((image) => (
            <div
              className={style.horizontalGalleryGridItem}
              key={image.file.url}
            >
              <img
                sizes="(min-width: 1400px) calc((100vw - (((100vw - 1360px) / 2)* 2)) / 3), (min-width: 768px) 34vw, 100vw"
                src={`${image.file.url}?w=660`}
                width={448}
                height={298}
                srcSet={`${image.file.url}?w=660 660w, ${image.file.url}?w=990 990w, ${image.file.url}?w=1320 1320w`}
              />
            </div>
          ))}
        </div>
      )}

      <div className={style.callToActionGrid}>
        <div className={style.callToActionGridItem}>
          <p>{data.contentfulPhotoshoot.callToAction.callToAction}</p>

          <a className={style.buttonLg} href="#photoshoot-packages">
            Pick your photoshoot
          </a>
        </div>
      </div>

      {data.contentfulPhotoshoot.secondGallery.length === 4 ? (
        <div className={style.verticalGalleryGrid}>
          {data.contentfulPhotoshoot.secondGallery.map((image) => (
            <div className={style.verticalGalleryGridItem} key={image.file.url}>
              <img
                sizes="(min-width: 1400px) calc((100vw - (((100vw - 1360px) / 2)* 2)) / 4), (min-width: 768px) 25vw, 100vw"
                src={`${image.file.url}?w=660`}
                width={332}
                height={498}
                srcSet={`${image.file.url}?w=660 660w, ${image.file.url}?w=990 990w, ${image.file.url}?w=1320 1320w`}
              />
            </div>
          ))}
        </div>
      ) : (
        <div className={style.horizontalGalleryGrid}>
          {data.contentfulPhotoshoot.secondGallery.map((image) => (
            <div
              className={style.horizontalGalleryGridItem}
              key={image.file.url}
            >
              <img
                sizes="(min-width: 1400px) calc((100vw - (((100vw - 1360px) / 2)* 2)) / 3), (min-width: 768px) 34vw, 100vw"
                src={`${image.file.url}?w=660`}
                width={448}
                height={298}
                srcSet={`${image.file.url}?w=660 660w, ${image.file.url}?w=990 990w, ${image.file.url}?w=1320 1320w`}
              />
            </div>
          ))}
        </div>
      )}

      <div className={style.packagesSection} id="photoshoot-packages">
        <h2 className={style.titleLg}>Pick your photoshoot package</h2>

        <div className={style.packagesSectionContent}>
          <div className={style.packagesGrid}>
            {data.contentfulPhotoshoot.packages.map((p) => (
              <div className={style.packagesGridItem} key={p.name}>
                <div className={style.packageCard}>
                  <h3 className={style.titleMd}>{p.name}</h3>

                  <div className={style.packageCardContent}>
                    <ul>
                      {p.details.map((detail) => (
                        <li key={detail}>{detail}</li>
                      ))}
                    </ul>
                  </div>

                  <div className={style.packageCardFooter}>
                    <span className={style.price}>€ {p.price},-</span>

                    <button
                      className={style.button}
                      onClick={() => setSelectedPackage(p.name)}
                    >
                      Book this package
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div
            className={style.packagesFootnotes}
            dangerouslySetInnerHTML={{
              __html: data.contentfulGeneral.content.childMarkdownRemark.html,
            }}
          />

          <PackageRequestDialog
            photoshootName={data.contentfulPhotoshoot.name}
            selectedPackage={selectedPackage}
            onClose={() => setSelectedPackage('')}
          />
        </div>
      </div>
    </Layout>
  )
}

function PackageRequestDialog({ photoshootName, onClose, selectedPackage }) {
  const elementRef = useRef(null)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    if (selectedPackage) {
      elementRef.current && elementRef.current.showModal()
    } else {
      elementRef.current && elementRef.current.close()
    }
  }, [selectedPackage])

  async function onSubmit(event) {
    const formData = new FormData(event.target)

    event.preventDefault()
    setPending(true)

    gtag('event', 'conversion_event_book_appointment', {
      event_callback: submitForm,
      event_timeout: 2000,
    })

    async function submitForm() {
      try {
        await fetch('/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: new URLSearchParams(formData).toString(),
        })

        onClose()
      } catch (err) {
        console.error(err)
      } finally {
        setPending(false)
      }
    }
  }

  return (
    <dialog ref={elementRef} className={style.packageRequestDialog}>
      <form
        className={style.packageRequestDialogForm}
        onSubmit={onSubmit}
        name="photoshoot"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="photoshoot" />
        <input type="hidden" name="photoshoot-name" value={photoshootName} />

        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </p>

        <span className={style.packageRequestDialogTitle}>Booking package</span>

        <div className={style.formRow}>
          <label>
            Chosen package
            <br />
            <input
              className={style.input}
              name="selected-package"
              type="text"
              value={selectedPackage}
              readOnly
            />
          </label>
        </div>

        <div className={style.formRow}>
          <label>
            Name
            <br />
            <input name="name" type="text" className={style.input} required />
          </label>
        </div>

        <div className={style.formRow}>
          <label>
            Email
            <br />
            <input name="email" type="email" className={style.input} required />
          </label>
        </div>

        <div className={style.formRow}>
          <label>
            Phone number
            <br />
            <input name="phone" type="tel" className={style.input} required />
          </label>
        </div>

        <div className={style.formRow}>
          <label>
            Message
            <br />
            <textarea
              name="message"
              required
              className={style.input}
              rows={5}
              placeholder="Please share your preferred dates and times for the photoshoot, along with any other details you find important."
            ></textarea>
          </label>
        </div>

        <div className={style.packageRequestDialogActions}>
          <button type="button" className={style.buttonNaked} onClick={onClose}>
            Cancel
          </button>

          <button type="submit" className={style.buttonLg}>
            Submit
          </button>
        </div>
      </form>
    </dialog>
  )
}

export const pageQuery = graphql`
  query PhotoshootPage($slug: String) {
    contentfulGeneral(type: { eq: "package-footnotes" }) {
      content {
        childMarkdownRemark {
          html
        }
      }
    }

    contentfulEntriesGroup(type: { eq: "Photoshoot" }) {
      items {
        name
        slug
      }
    }

    allContentfulPackageType {
      nodes {
        name
        slug
      }
    }

    contentfulPhotoshoot(slug: { eq: $slug }) {
      id
      name

      description {
        description
      }

      callToAction {
        callToAction
      }

      firstGallery {
        file {
          url
        }
      }

      secondGallery {
        file {
          url
        }
      }

      packages {
        name
        details
        price
      }
    }
  }
`

export default PhotoshootPage
